* {
    border: 0;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    height: inherit;
}

@media print { 
	 #app { display: block !important; } 
}

#app {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;

    font-family: Open Sans, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;

    font-size: 14px;
    font-weight: 400;

    color: #555555;
    margin: 0;

    white-space: normal;

    word-wrap: normal;
}

a {
    text-decoration: none !important;
}

a:hover {
    text-decoration: underline;
}

.active {
    text-decoration: underline;
}

#active {
    text-decoration: underline;
}

/* remove dotted outline in firefox */
:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

/* remove input borders */
input,
button,
textarea {
    outline: none;
    background-color: transparent !important;
    border: 0 solid;
    /*font: inherit;*/
    color: inherit;
}

/* remove yellow background in login inputs*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

/* remove arrows in inputs*/
input:focus {
    outline: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/*
    Custom scrollbar style
*/
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
