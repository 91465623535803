.toolbarIcon {
    font-size: 30px;
    margin-right: 10px;
    position: absolute;
}

.toolbar {
	margin-right: 10px;
}

#seadragon {
    height: 800px;
    margin-top: 20px;
}