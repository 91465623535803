.sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
    transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
    transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
    transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
    transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
    transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
    transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
    transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
    transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
    transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
    transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.toolbarIcon {
    font-size: 30px;
    margin-right: 10px;
    position: absolute;
}

.toolbar {
	margin-right: 10px;
}

#seadragon {
    height: 800px;
    margin-top: 20px;
}
.sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
    transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
    transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
    transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
    transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
    transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
    transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
    transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
    transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
    transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
    transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

* {
    border: 0;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    height: inherit;
}

@media print { 
	 #app { display: block !important; } 
}

#app {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;

    font-family: Open Sans, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;

    font-size: 14px;
    font-weight: 400;

    color: #555555;
    margin: 0;

    white-space: normal;

    word-wrap: normal;
}

a {
    text-decoration: none !important;
}

a:hover {
    text-decoration: underline;
}

.active {
    text-decoration: underline;
}

#active {
    text-decoration: underline;
}

/* remove dotted outline in firefox */
:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

/* remove input borders */
input,
button,
textarea {
    outline: none;
    background-color: transparent !important;
    border: 0 solid;
    /*font: inherit;*/
    color: inherit;
}

/* remove yellow background in login inputs*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

/* remove arrows in inputs*/
input:focus {
    outline: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/*
    Custom scrollbar style
*/
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

